import React from 'react'

function PredictionIconwhite() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 18.4375C13.1 18.4375 11.5625 16.9 11.5625 15C11.5625 13.1 13.1 11.5625 15 11.5625C16.9 11.5625 18.4375 13.1 18.4375 15C18.4375 16.9 16.9 18.4375 15 18.4375ZM15 13.4375C14.1375 13.4375 13.4375 14.1375 13.4375 15C13.4375 15.8625 14.1375 16.5625 15 16.5625C15.8625 16.5625 16.5625 15.8625 16.5625 15C16.5625 14.1375 15.8625 13.4375 15 13.4375Z" fill="#fff"/>
    <path d="M25.0004 23.4379C24.8004 23.4379 24.6128 23.3754 24.4378 23.2504C24.0253 22.9379 23.9378 22.3503 24.2503 21.9378C25.7628 19.9253 26.5629 17.5254 26.5629 15.0004C26.5629 12.4754 25.7628 10.0754 24.2503 8.06287C23.9378 7.65037 24.0253 7.06284 24.4378 6.75034C24.8503 6.43784 25.4379 6.52534 25.7504 6.93784C27.5129 9.27534 28.4379 12.0629 28.4379 15.0004C28.4379 17.9379 27.5129 20.7254 25.7504 23.0629C25.5629 23.3129 25.2879 23.4379 25.0004 23.4379Z" fill="#fff"/>
    <path d="M5 23.4379C4.7125 23.4379 4.43749 23.3129 4.24999 23.0629C2.48749 20.7254 1.5625 17.9379 1.5625 15.0004C1.5625 12.0629 2.48749 9.27534 4.24999 6.93784C4.56249 6.52534 5.15002 6.43784 5.56252 6.75034C5.97502 7.06284 6.06251 7.65037 5.75001 8.06287C4.23751 10.0754 3.4375 12.4754 3.4375 15.0004C3.4375 17.5254 4.23751 19.9253 5.75001 21.9378C6.06251 22.3503 5.97502 22.9379 5.56252 23.2504C5.40002 23.3754 5.2 23.4379 5 23.4379Z" fill="#fff"/>
    <path d="M21.0003 20.4379C20.8003 20.4379 20.6128 20.3754 20.4378 20.2504C20.0253 19.9379 19.9378 19.3503 20.2503 18.9378C21.1128 17.8003 21.5628 16.4378 21.5628 15.0003C21.5628 13.5628 21.1128 12.2004 20.2503 11.0629C19.9378 10.6504 20.0253 10.0628 20.4378 9.75033C20.8503 9.43783 21.4378 9.52536 21.7503 9.93786C22.8503 11.4129 23.4378 13.1628 23.4378 15.0003C23.4378 16.8378 22.8503 18.6003 21.7503 20.0628C21.5628 20.3128 21.2878 20.4379 21.0003 20.4379Z" fill="#fff"/>
    <path d="M9.00002 20.4379C8.71252 20.4379 8.43751 20.3128 8.25001 20.0628C7.15001 18.5878 6.5625 16.8378 6.5625 15.0003C6.5625 13.1628 7.15001 11.4004 8.25001 9.93786C8.56251 9.52536 9.14999 9.43783 9.56249 9.75033C9.97499 10.0628 10.0625 10.6504 9.74998 11.0629C8.88748 12.2004 8.4375 13.5628 8.4375 15.0003C8.4375 16.4378 8.88748 17.8003 9.74998 18.9378C10.0625 19.3503 9.97499 19.9379 9.56249 20.2504C9.39999 20.3754 9.20002 20.4379 9.00002 20.4379Z" fill="#fff"/>
    </svg>
    
  )
}

export default PredictionIconwhite