import React, { useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";

HighchartsMore(Highcharts); // Enable extra chart types

function SemiPie({ value, effortScore }) {
  useEffect(() => {
    Highcharts.chart("semipie", {
      chart: {
        type: "pie",
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
      },
      title: {
        text: "", // No title
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false, // Hide default labels
          },
          startAngle: -90,
          endAngle: 90,
          center: ["50%", "80%"], // Adjust center
          size: "90%", // Overall pie size
          innerSize: "75%", // Makes it slim
        },
      },
      series: [
        {
          type: "pie",
          name: "Effort Distribution",
          innerSize: "75%",
          data: value.map((point) => ({
            name: point.name,
            y: point.y,
            color: point.color || getRandomColor(),
          })),
        },
        // Needle Indicator
        {
          type: "pie",
          name: "Needle",
          data: [
            {
              y: 10, // Increased for thicker needle
              color: "rgba(0, 0, 0, 0.9)", // Darker black
              dataLabels: { enabled: false },
            },
            {
              y: 90,
              color: "black",
              dataLabels: { enabled: false },
            },
          ],
          innerSize: "50%", // Reduce inner size for a thicker look
          size: "92%",
          startAngle: effortScore * 1.8 - 90, // Convert score (0-100) to angle
          endAngle: effortScore * 1.8 - 87, // Make it wider
        },
      ],
    });
  }, [value, effortScore]);

  // Function to generate a random color
  const getRandomColor = () => {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  };

  return (
    <div style={{ position: "relative", width: "500px", height: "200px" }}>
      {/* Highcharts container */}
      <div id="semipie" style={{ width: "100%", height: "100%" }} />
      
      {/* Effort Score Text */}
      <div
        style={{
          position: "absolute",
          top: "60%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "14px",
          fontWeight: "bold",
          textAlign:"center"
        }}
      >
       {effortScore}<br/> Effort Score 
      </div>
    </div>
  );
}

export default SemiPie;
