import React from 'react'

function BadgeIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.7083 15.3914L16.3333 15.7164C16.025 15.7914 15.7833 16.0248 15.7166 16.3331L15.425 17.5581C15.2666 18.2248 14.4166 18.4331 13.975 17.9081L11.4833 15.0414C11.2833 14.8081 11.3916 14.4414 11.6916 14.3664C13.1666 14.0081 14.4916 13.1831 15.4666 12.0081C15.625 11.8164 15.9083 11.7914 16.0833 11.9664L17.9333 13.8164C18.5666 14.4498 18.3416 15.2414 17.7083 15.3914Z" fill="#F9BE02"/>
<path d="M2.24989 15.3914L3.62489 15.7164C3.93322 15.7914 4.17489 16.0248 4.24156 16.3331L4.53322 17.5581C4.69156 18.2248 5.54156 18.4331 5.98322 17.9081L8.47489 15.0414C8.67489 14.8081 8.56656 14.4414 8.26656 14.3664C6.79156 14.0081 5.46656 13.1831 4.49156 12.0081C4.33322 11.8164 4.04989 11.7914 3.87489 11.9664L2.02489 13.8164C1.39156 14.4498 1.61656 15.2414 2.24989 15.3914Z" fill="#F9BE02"/>
<path d="M10.0001 1.6665C6.77508 1.6665 4.16675 4.27484 4.16675 7.49984C4.16675 8.70817 4.52508 9.8165 5.14175 10.7415C6.04175 12.0748 7.46675 13.0165 9.12508 13.2582C9.40841 13.3082 9.70008 13.3332 10.0001 13.3332C10.3001 13.3332 10.5917 13.3082 10.8751 13.2582C12.5334 13.0165 13.9584 12.0748 14.8584 10.7415C15.4751 9.8165 15.8334 8.70817 15.8334 7.49984C15.8334 4.27484 13.2251 1.6665 10.0001 1.6665ZM12.5501 7.3165L11.8584 8.00817C11.7417 8.12484 11.6751 8.34984 11.7167 8.5165L11.9167 9.37484C12.0751 10.0498 11.7167 10.3165 11.1167 9.95817L10.2834 9.4665C10.1334 9.37484 9.88341 9.37484 9.73341 9.4665L8.90008 9.95817C8.30008 10.3082 7.94175 10.0498 8.10008 9.37484L8.30008 8.5165C8.33341 8.35817 8.27508 8.12484 8.15841 8.00817L7.45008 7.3165C7.04175 6.90817 7.17508 6.49984 7.74175 6.40817L8.63341 6.25817C8.78341 6.23317 8.95841 6.09984 9.02508 5.9665L9.51675 4.98317C9.78341 4.44984 10.2167 4.44984 10.4834 4.98317L10.9751 5.9665C11.0417 6.09984 11.2167 6.23317 11.3751 6.25817L12.2667 6.40817C12.8251 6.49984 12.9584 6.90817 12.5501 7.3165Z" fill="#F9BE02"/>
</svg>

  )
}

export default BadgeIcon