import React from 'react'

function SearchIconwhite() {
  return (
    <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.75 25.9375C7.025 25.9375 1.5625 20.475 1.5625 13.75C1.5625 7.025 7.025 1.5625 13.75 1.5625C20.475 1.5625 25.9375 7.025 25.9375 13.75C25.9375 20.475 20.475 25.9375 13.75 25.9375ZM13.75 3.4375C8.0625 3.4375 3.4375 8.0625 3.4375 13.75C3.4375 19.4375 8.0625 24.0625 13.75 24.0625C19.4375 24.0625 24.0625 19.4375 24.0625 13.75C24.0625 8.0625 19.4375 3.4375 13.75 3.4375Z" fill="#fff"/>
    <path d="M25.1994 28.4875C25.0994 28.4875 24.9994 28.475 24.9119 28.4625C24.3244 28.3875 23.2619 27.9875 22.6619 26.2C22.3494 25.2625 22.4619 24.325 22.9744 23.6125C23.4869 22.9 24.3494 22.5 25.3369 22.5C26.6119 22.5 27.6119 22.9875 28.0619 23.85C28.5119 24.7125 28.3869 25.8125 27.6744 26.875C26.7869 28.2125 25.8244 28.4875 25.1994 28.4875ZM24.4494 25.6125C24.6619 26.2625 24.9619 26.5875 25.1619 26.6125C25.3619 26.6375 25.7369 26.4 26.1244 25.8375C26.4869 25.3 26.5119 24.9125 26.4244 24.7375C26.3369 24.5625 25.9869 24.375 25.3369 24.375C24.9494 24.375 24.6619 24.5 24.4994 24.7125C24.3494 24.925 24.3244 25.25 24.4494 25.6125Z" fill="#fff"/>
    </svg>
    
  )
}

export default SearchIconwhite