import React, { useEffect } from 'react';
import Highcharts from 'highcharts';

function CesPie({ value, id }) {
  useEffect(() => {
    Highcharts.chart(id, {
      chart: {
        type: 'pie'
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: '<span style="color:{point.color}">\u25CF</span> <b>{point.y} ({point.percentage:.1f}%)</b>'
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true, // Enable labels
            format: '{point.percentage:.1f}%', // Show percentage
            distance: -30, // Position inside the slice
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#fff', // Ensure contrast inside slices
              textOutline: 'none' // Remove outline for better visibility
            }
          }
        }
      },
      series: [
        {
          colorByPoint: true,
          data: value.map(point => ({
            ...point,
            color: point.color || getRandomColor() // Use provided color or generate a random one
          }))
        }
      ]
    });
  }, [value, id]);

  // Function to generate a random color
  const getRandomColor = () => {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  };

  return (
    <div id={id} style={{ width: '100%', height: '200px' }}></div>
  );
}

export default CesPie;
