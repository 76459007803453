import { Grid, ThemeProvider } from "@mui/material";
import Sidenav from "./components/sidenav/sidenav";
import { theme } from "./theme";

import { Provider } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Authentication from "./authentication/authentication";
import store from "./redux/store/configure-store";
import HomeView from "./views/homeview/homeview";
import Leaderboardview from "./views/leaderboardview/leaderboardview";
import Logout from "./views/logoutview/logout";
import Supportqualityview from "./views/supportquality/supportquality";

function App() {
  const location = useLocation();
  const shouldShowNavbarAndSidebar =
    !["/logout", "/slack/auth"].includes(location.pathname) &&
    !location.pathname.includes("/detailed-doc");
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Grid display="flex">
          {shouldShowNavbarAndSidebar && <Sidenav />}
          <Grid
            mt={shouldShowNavbarAndSidebar ? "70px" : null}
            sx={shouldShowNavbarAndSidebar && { width: "85%", flex: "1 1" }}
          >
            <Routes>
              <Route
                path="/"
                element={
                  <Navigate to={`/${localStorage.getItem("ci")}/home`} />
                }
              />
              <Route
                path="/:customer_uuid/validate/:access_token/:refresh_token"
                element={<Authentication />}
              />
          
              <Route path="/:customer_uuid/home" element={<HomeView />} />
             
           
            <Route path="/:customer_uuid/leaderboard" element={<Leaderboardview/>} />

              <Route path="/:customer_uuid/supportquality" element={<Supportqualityview/>} />
             

              <Route path="/home" element={<HomeView />} />

              <Route path="/logout" element={<Logout />} />
            </Routes>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
